import { graphql } from 'gatsby'
import React, { FC } from 'react'

import useSiteSettings from '../../../hooks/useSiteSettings'
import { drugNutrientPaths } from '../../../lib/drug-nutrient/config'
import Link from '../../global/Link'
import { DrugNutrientLayout } from '../DrugNutrientLayout'
import { DrugNutrientSearch } from '../DrugNutrientSearch'
import * as Styled from './styled'

const slugToName = (slug: string) => {
  return slug
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const DrugNutrientHomePage: FC = () => {
  const { top25Drugs } = useSiteSettings() ?? {}
  return (
    <DrugNutrientLayout>
      <DrugNutrientSearch />
      {top25Drugs && top25Drugs.length > 0 && (
        <Styled.HomeSection>
          <h2>Top 25 Prescribed Drugs</h2>
          <Styled.Chips>
            {top25Drugs.map((drug, index) =>
              drug && (drug.name || drug.slug?.current) ? (
                <Styled.Chip
                  key={drug.slug?.current ?? `drug-${index}`}
                  variant="outlined"
                  label={
                    drug.name
                      ? drug.name
                      : drug.slug?.current
                        ? slugToName(drug.slug.current)
                        : undefined
                  }
                  // `component` prop from MUI is not typed/inferred correctly and marked as non-existent,
                  // however it exists for all/most MUI components: https://v4.mui.com/components/chips/
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  component={Link}
                  to={
                    drug?.slug?.current
                      ? drugNutrientPaths.drugDetailsPage.absolute(drug.slug.current)
                      : undefined
                  }
                  clickable={!!drug?.slug?.current}
                />
              ) : (
                <React.Fragment key={`drug-${index}`} />
              ),
            )}
          </Styled.Chips>
        </Styled.HomeSection>
      )}
      <Styled.HomeSection>
        <Styled.Disclaimer>
          The DNIC is not a medical device, or there to replace the advice of the primary care
          physician, it is for educational and general information purposes only and is not intended
          to provide any diagnosis, cure, prevention or recommendation for medical treatment of any
          disease.
        </Styled.Disclaimer>
      </Styled.HomeSection>
    </DrugNutrientLayout>
  )
}

export const top25Drugs = graphql`
  fragment top25Drugs on SanitySiteSettings {
    top25Drugs {
      slug {
        current
      }
      name
    }
  }
`
